<template>
    <div>
        <Breadcrumb titulo="Relatório WhatsApp Business" :items="itensBreadcrumb" backgroundColor="#32ba46" />

        <div class="container-fluid py-4">
            <div id="container-gs-whatsbusiness" class="border p-2">

                <section id="secao-selecionador-data" class="d-flex align-items-end w-25 p-2">
                    <InputSelect label="Mês" :opcoes="listaMeses" v-model="mesSelecionado" class="mr-2" />
                    <InputSelect label="Ano" :opcoes="listaAnos" v-model="anoSelecionado" class="mr-2" />

                    <BotaoPadraoCinza @click="consultarRelatorioMensagens">Consultar</BotaoPadraoCinza>
                </section>

                <section id="tabela-mensal-mensagens-whatsapp" class="w-25 pl-2 mt-2">
                    <small>Mensagens de {{listaMeses[mesSelecionado - 1].text}} de {{anoSelecionado}}</small>

                    <b-table :fields="fieldsMensagensMes"
                             :items="mensagensMes"
                             :busy="carregandoDadosGupShup"
                             foot-clone
                             foot-variant="light"
                             bordered
                             fixed
                             small>

                        <template #foot(dia)="data">
                            <span>Total</span>
                        </template>

                        <template #foot(recebidasNoDia)="data">
                            <span>{{ totalMensagensMes.recebidas }}</span>
                        </template>

                        <template #foot(enviadasNoDia)="data">
                            <span>{{ totalMensagensMes.enviadas }}</span>
                        </template>

                        <template #table-busy>
                            <div class="text-center my-2">
                                <b-spinner variant="success" class="align-middle"></b-spinner>
                                <p>Carregando mensagens...</p>
                            </div>
                        </template>

                    </b-table>
                </section>

            </div>
        </div>

    </div>
</template>

<script>
    import Breadcrumb from '@/components/breadcrumb/Breadcrumb.vue'
    import InputSelect from '@/components/inputs/InputSelect.vue'
    import BotaoPadraoCinza from '@/components/botoes/BotaoPadraoCinza.vue'

    import sharkHubServices from '@/assets/js/services/SharkHubServices.js'

    export default {
        name: 'ConsultaMensagensPage',

        components: {
            Breadcrumb,
            InputSelect,
            BotaoPadraoCinza
        },

        prop: {
            titulo: String,
            subtitulo: String,
            classe: String,
            cor: String,
        },

        data: function () {
            return {
                itensBreadcrumb: [
                    {
                        id: '1',
                        classe: 'fas fa-search',
                        texto: 'Consultas',
                        link: '/consultas'
                    },
                    {
                        id: '2',
                        classe: 'fas fa-whatsapp',
                        texto: 'Relatório Whatsapp Business',
                        link: ''
                    },
                ],
                carregandoDadosGupShup: false,
                anoSelecionado: 2022,
                mesSelecionado: 1,
                listaMeses: [
                    { text: 'Janeiro', value: 1 },
                    { text: 'Fevereiro', value: 2 },
                    { text: 'Março', value: 3 },
                    { text: 'Abril', value: 4 },
                    { text: 'Maio', value: 5 },
                    { text: 'Junho', value: 6 },
                    { text: 'Julho', value: 7 },
                    { text: 'Agosto', value: 8 },
                    { text: 'Setembro', value: 9 },
                    { text: 'Outubro', value: 10 },
                    { text: 'Novembro', value: 11 },
                    { text: 'Dezembro', value: 12 },
                ],
                listaAnos: [
                    { text: '2021', value: 2021 },
                    { text: '2022', value: 2022 }
                ],
                fieldsMensagensMes: [
                    { key: 'dia' },
                    { key: 'recebidasNoDia', label: 'Recebidos' },
                    { key: 'enviadasNoDia', label: 'Enviadas' }
                ],
                mensagensMes: []
            }
        },

        computed: {
            totalMensagensMes() {
                return {
                    recebidas: this.mensagensMes?.reduce((n, { recebidasNoDia }) => n + recebidasNoDia, 0),
                    enviadas: this.mensagensMes?.reduce((n, { enviadasNoDia }) => n + enviadasNoDia, 0)
                }
            }
        },

        methods: {
            consultarRelatorioMensagens() {
                this.carregandoDadosGupShup = true;
                var response = sharkHubServices.getConsumoMensagensWhatsBusiness(this.mesSelecionado, this.anoSelecionado);

                response.then(res => {
                    this.carregandoDadosGupShup = false;
                    this.mensagensMes = []

                    Object.entries(res.dados.message).forEach(([dataMensagem, mensagens]) => {
                        if (mensagens.length) {
                            this.mensagensMes.push({
                                dia: dataMensagem.split('-')[2],
                                recebidasNoDia: mensagens?.find(x => x.service === 'gs_wa_session_in')?.count ?? 0,
                                enviadasNoDia: mensagens?.find(x => x.service === 'gs_wa_session_out')?.count ?? 0
                            });
                        }
                    })
                });
            }
        },

        mounted() {
            let dataAtual = new Date().toLocaleDateString('pt-BR');
            dataAtual = dataAtual.split('/');
            this.mesSelecionado = parseInt(dataAtual[1]);
            this.anoSelecionado = parseInt(dataAtual[2]);
            this.consultarRelatorioMensagens();
        }
    }
</script>

<style scoped></style>